import React, { useEffect, useState } from "react";
import useBattery from "./../../../hooks/useBattery";
import { useSensorContext } from "../../../contexts/sensor.context";
import constant, { BatterySensor } from "../../../helpers/constant";
import FeatureItem from "../../common/FeatureItem";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";

const Battery = () => {
  const existingData = getStorageItem(BatterySensor.id);
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(existingData?.active);
  const {
    isSupported,
    fetched,
    level,
    charging,
    dischargingTime,
    chargingTime,
  } = useBattery(recording, {
    frequency: existingData?.frequency,
  });

  useEffect(() => {
    if (level && recording) {
      sendSensorData(BatterySensor.id, { level });
    }
  }, [level]);

  useEffect(() => {
    const data = getStorageItem(BatterySensor.id);
    if (data && !data.frequency) {
      data.frequency = constant.defaultFrequency;
    }
    setStorageItem(BatterySensor.id, { ...data, active });
  }, [active]);

  const render = () => {
    return (
      <>
        <FeatureItem
          item={BatterySensor}
          active={active}
          setActive={() => setActive(!active)}
        />
      </>
    );
  };
  return render();
};

export default Battery;
