// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  recording: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_RECORDING:
      return {
        ...state,
        recording: action.payload,
      };
    default:
      return state;
  }
};

const useSensorReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useSensorReducer;
