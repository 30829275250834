import React, { createContext, useContext, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import useAuthReducer from "../reducers/auth.reducer";
import * as ACTION from "../reducers/types";
import constant, { ToastType } from "../helpers/constant";
import { clearSharedInfo, setSharedInfo } from "../helpers/shared-info";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { appLogout, auth } from "../../firebase";
import { toast } from "react-toastify";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { state, dispatch } = useAuthReducer();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: loading });
    if (error) {
      dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
      dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: error });
      // showError(error);
    }
    if (user) {
      const org_tenant = user.tenantId;
      setSharedInfo(constant.SHARED.BEARER_TOKEN, user.accessToken);
      dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
      dispatch({
        type: ACTION.USER_LOGIN_SUCCESS,
        payload: { user },
      });
    }
  }, [user, error, loading]);

  const login = (user, cb) => {
    const { username, password, company } = user;
    auth.tenantId = company;
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    signInWithEmailAndPassword(auth, username, password)
      .then((r) => console.log(r))
      .catch((ex) => {
        dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
        dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: ex });
      });
  };

  const requestPasswordReset = (
    username,
    company,
    cb,
    isStateUpdate,
    error
  ) => {
    if (isStateUpdate)
      dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: null });
    auth.tenantId = company;
    sendPasswordResetEmail(auth, username)
      .then(() => {
        cb && cb();
      })
      .catch((ex) => error && error(ex));
  };

  const logout = () => {
    appLogout(() => {
      clearStorge();
      window.location.href = "/";
    });
  };

  const clearStorge = () => {
    clearSharedInfo();
    window.localStorage.clear();
    window.sessionStorage.clear();
  };

  const showError = (message) => {
    toast(message, { autoClose: true, type: ToastType.ERROR });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        showError,
        logout,
        requestPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, AuthContext };
