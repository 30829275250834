import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/app.context";
import { useAuthContext } from "../../contexts/auth.context";
import CustomLoader from "../common/CustomLoader";
import { validateEmail } from "../../helpers/helper";
import constant from "../../helpers/constant";

const LoginForm = () => {
  const { login, authError, requestPasswordReset } = useAuthContext();
  const { showAlert } = useAppContext();
  const [loading, setLoading] = useState();
  // rohit.abraham02@gmail.com
  // Bitwise@01
  // incorp-1zfpt
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [form, setForm] = useState({ error: {} });
  const [isResetDone, setIsResetDone] = useState(false);

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!username) {
      formIsValid = false;
      error["username"] = "Username is required.";
    }
    if (username && !validateEmail(username)) {
      formIsValid = false;
      error["username"] = "Enter valid username (email/phone no).";
    }
    if (!password) {
      formIsValid = false;
      error["password"] = "Password is required.";
    }
    setForm({ error });
    return formIsValid;
  };

  const submitPasswordReset = () => {
    let error = {};
    if (!username) {
      error["username"] = "Username is required.";
      setForm({ error });
      return false;
    }
    if (username && !validateEmail(username)) {
      error["username"] = "Enter valid username (email/phone no).";
      setForm({ error });
      return false;
    }
    setLoading(true);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      login({ username, password, company: "benihime-d1bza" });
      clearForm();
    }
  };

  const clearForm = () => {
    setUsername("");
    setPassword("");
    setForm({ error: {} });
  };

  const renderLogin = () => {
    return (
      <div className="mb-4 pt-4 text-center">
        <button className="btn1" type="submit">
          Login
        </button>
      </div>
    );
  };

  const renderGeneratePassword = () => {
    return (
      <Link onClick={() => submitPasswordReset()} className="text-blue">
        Recover your password
      </Link>
    );
  };

  const renderLoginForm = () => {
    return (
      <section className="loginsec p-5">
        <div className="app-name">{constant.AppTitle}</div>
        <form onSubmit={onFormSubmit} className="w-100">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Username"
              className={`form-control ${
                form.error["username"] ? "error-br" : ""
              }`}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {form.error["username"] && (
              <small className="text-danger">{form.error["username"]}</small>
            )}
          </div>
          <div className="mb-4">
            <input
              type="password"
              className={`form-control ${
                form.error["password"] ? "error-br" : ""
              }`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            {form.error["password"] && (
              <small className="text-danger">{form.error["password"]}</small>
            )}
          </div>
          {renderLogin()}
        </form>
      </section>
    );
  };

  const render = () => {
    return (
      <>
        {loading && <CustomLoader full />}
        {renderLoginForm()}
      </>
    );
  };
  return render();
};

export default LoginForm;
