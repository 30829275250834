import React, { useEffect, useState } from "react";
import useGeolocation from "./../../../hooks/useGeoLocation";
import { Link } from "react-router-dom";
import constant, { GeoLocationSensor } from "../../../helpers/constant";
import { useSensorContext } from "../../../contexts/sensor.context";
import FeatureItem from "../../common/FeatureItem";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";

const Geolocation = () => {
  const existingData = getStorageItem(GeoLocationSensor.id);
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(existingData?.active);
  const { latitude, longitude, altitude, speed, error } = useGeolocation(
    recording && active,
    {
      frequency: existingData?.frequency,
    }
  );

  useEffect(() => {
    const data = getStorageItem(GeoLocationSensor.id);
    if (data && !data.frequency) {
      data.frequency = constant.defaultFrequency;
    }
    setStorageItem(GeoLocationSensor.id, { ...data, active });
  }, [active]);

  const renderGeoLocationInstructions = () => {
    return (
      <Link
        href="https://support.google.com/chrome/answer/142065?hl=en"
        target="_blank"
        rel="noopener"
      >
        How to allow permissions.
      </Link>
    );
  };

  useEffect(() => {
    if (latitude != null && longitude != null) {
      sendSensorData(GeoLocationSensor.id, {
        latitude,
        longitude,
        altitude,
        speed,
      });
    }
  }, [latitude, longitude, altitude, speed]);

  const render = () => {
    if (error) {
      return renderGeoLocationInstructions();
    }
    return (
      <>
        <FeatureItem
          item={GeoLocationSensor}
          active={active}
          setActive={() => setActive(!active)}
        />
      </>
    );
  };
  return render();
};

export default Geolocation;
