import React, { createContext, useContext } from "react";
import * as ACTION from "./../reducers/types";
import useSensorReducer from "../reducers/sensor.reducer";
import { postSensorMessage } from "../services/api.service";
import { getTimeStamp } from "../helpers/helper";
import { useAppContext } from "./app.context";

const SensorContext = createContext();

const SensorProvider = ({ children }) => {
  const { state, dispatch } = useSensorReducer();
  const { deviceId } = useAppContext();

  const updateRecording = (payload) =>
    dispatch({ type: ACTION.UPDATE_RECORDING, payload });

  const sendSensorData = (channel, data) => {
    data = { ...data, timestamp: getTimeStamp() };
    console.log({ data });
    postSensorMessage({ deviceId, channel, data }).then().catch(console.log);
  };

  return (
    <SensorContext.Provider
      value={{
        ...state,
        updateRecording,
        sendSensorData,
      }}
    >
      {children}
    </SensorContext.Provider>
  );
};

export const useSensorContext = () => useContext(SensorContext);

export { SensorProvider, SensorContext };
