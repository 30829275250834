import React, { useState } from "react";
import constant from "../../helpers/constant";
import { useAuthContext } from "../../contexts/auth.context";
import { useAppContext } from "../../contexts/app.context";

const AppHeader = () => {
  const { logout } = useAuthContext();
  const { deviceId } = useAppContext();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (status) => () => {
    setOpen(status);
  };

  const onLogout = () => {
    logout();
    setOpen(false);
  };

  const render = () => {
    return (
      <div className="app-header">
        <div className="app-title">{constant.AppTitle}</div>
      </div>
    );
  };
  return render();
};

export default AppHeader;
