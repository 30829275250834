import { useState, useEffect, useRef } from "react";
import constant from "../helpers/constant";
import { getFrequencyForSample } from "../helpers/helper";

const useMicrophoneStrength = (recording, { frequency }) => {
  const [dBLevel, setDBLevel] = useState(0);
  const audioContextRef = useRef(null);
  const analyserNodeRef = useRef(null);
  const microphoneStreamRef = useRef(null);
  const intervalId = useRef(null);

  useEffect(() => {
    if (recording) {
      const getMicrophoneAccess = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          microphoneStreamRef.current = stream;

          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          audioContextRef.current = audioContext;

          const source = audioContext.createMediaStreamSource(stream);
          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 256;
          source.connect(analyser);
          analyserNodeRef.current = analyser;

          const dataArray = new Uint8Array(analyser.frequencyBinCount);

          const calculateDBLevel = () => {
            analyser.getByteFrequencyData(dataArray);
            let values = 0;

            for (let i = 0; i < dataArray.length; i++) {
              values += dataArray[i];
            }

            const average = values / dataArray.length;
            const dB = 20 * Math.log10(average / 255);
            setDBLevel(dB);
          };
          const time = getFrequencyForSample(frequency);
          console.log({ time });
          intervalId.current = setInterval(calculateDBLevel, time);

          return () => {
            clearMicrophone();
          };
        } catch (error) {
          console.error("Error accessing microphone:", error);
        }
      };
      getMicrophoneAccess();
    } else {
      clearMicrophone();
    }
  }, [recording]);

  const clearMicrophone = () => {
    clearInterval(intervalId.current);
    if (microphoneStreamRef.current) {
      microphoneStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  };

  return dBLevel;
};

export default useMicrophoneStrength;
