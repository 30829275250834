import React, { createContext, useContext } from "react";
import useAppReducer from "../reducers/app.reducer";
import * as ACTION from "./../reducers/types";
import constant, { ToastType } from "../helpers/constant";
import { getStorageItem, setStorageItem } from "../helpers/shared-info";
import { toast } from "react-toastify";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { state, dispatch } = useAppReducer();

  const updateLoader = (flag) =>
    dispatch({ type: ACTION.UPDATE_LOADER, payload: flag });

  const setDeviceId = () => {
    const deviceId = getStorageItem(constant.STORAGE.deviceId);
    if (!deviceId) {
      getFingerprint().then((value) => {
        setStorageItem(constant.STORAGE.deviceId, value);
        dispatch({ type: ACTION.UPDATE_DEVICE_ID, payload: value });
      });
    } else dispatch({ type: ACTION.UPDATE_DEVICE_ID, payload: deviceId });
  };

  const showAlert = (
    message,
    appearance = ToastType.INFO,
    autoClose = true
  ) => {
    if (message) {
      toast(message, { autoClose, type: appearance });
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateLoader,
        setDeviceId,
        showAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export { AppProvider, AppContext };
