import React from "react";
import { AppProvider } from "./contexts/app.context";
import { AuthProvider } from "./contexts/auth.context";
import Navigator from "./navigator/Navigator";
import { BrowserRouter } from "react-router-dom";
import { SensorProvider } from "./contexts/sensor.context";

// Import Bootstrap CSS and JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery/dist/jquery.slim.js";

const App = () => {
  const render = () => {
    return (
      <AppProvider>
        <AuthProvider>
          <SensorProvider>
            <BrowserRouter>
              <Navigator />
            </BrowserRouter>
          </SensorProvider>
        </AuthProvider>
      </AppProvider>
    );
  };
  return render();
};

export default App;
