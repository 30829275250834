// useAccelerometer.js
import { useState, useEffect } from "react";
import constant, { SensorType } from "../helpers/constant";

const useSensor = (
  type = SensorType.Accelerometer,
  recording = false,
  options = {
    frequency: constant.defaultSensorFrequency,
    referenceFrame: "device",
  }
) => {
  const [acceleration, setAcceleration] = useState({
    x: null,
    y: null,
    z: null,
    quaternion: null,
    error: null,
  });

  const requestPermission = (sensor) => {
    Promise.all([
      navigator.permissions.query({ name: "accelerometer" }),
      navigator.permissions.query({ name: "magnetometer" }),
      navigator.permissions.query({ name: "gyroscope" }),
    ]).then((results) => {
      if (results.every((result) => result.state === "granted")) {
        sensor.start();
        // …
      } else {
        console.log(`No permissions to use AbsoluteOrientationSensor.`);
      }
    });
  };

  useEffect(() => {
    if (!window[type]) {
      return;
    }
    const sensor = new window[type](options);
    const handleReading = () => {
      setAcceleration({
        x: sensor.x,
        y: sensor.y,
        z: sensor.z,
        quaternion: sensor.quaternion,
        error: null,
      });
    };

    const handleError = (event) => {
      setAcceleration((prevState) => ({
        ...prevState,
        error: event.error.message,
      }));
    };
    if (recording) {
      if (!(type in window)) {
        setAcceleration((prevState) => ({
          ...prevState,
          error: `${type} is not supported by your browser`,
        }));
        return;
      }
      requestPermission(sensor);

      sensor.addEventListener("reading", handleReading);
      sensor.addEventListener("error", handleError);
    }
    return () => {
      sensor.removeEventListener("reading", handleReading);
      sensor.removeEventListener("error", handleError);
      sensor.stop();
    };
  }, [recording]);

  return acceleration;
};

export default useSensor;
