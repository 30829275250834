import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/auth.context";

const Protected = ({ children }) => {
  const { isLoggedIn } = useAuthContext();
  if (!isLoggedIn) return <Navigate to="/" replace />;

  return children;
};
export default Protected;
