import PageNotFound from "../components/common/PageNotFound";
import constant from "../helpers/constant";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import Features from "./../components/home/features/Features";
import Recordings from "./../components/home/recordings/Recordings";
import Settings from "./../components/home/settings/Settings";

const authRoutes = [
  {
    path: ``,
    element: <LoginPage />,
  },
  {
    path: `${constant.PAGES.AUTH.LOGIN}`,
    element: <LoginPage />,
  },
  {
    path: `*`,
    element: <LoginPage />,
  },
];
const appRoutes = [
  {
    path: ``,
    element: <DashboardPage />,
    children: [
      {
        path: `/`,
        element: <Features />,
      },
      {
        path: `/${constant.PAGES.HOME.HOME}`,
        element: <Features />,
      },
      {
        path: `/${constant.PAGES.HOME.RECORDINGS}`,
        element: <Recordings />,
      },
      {
        path: `/${constant.PAGES.HOME.SETTINGS}`,
        element: <Settings />,
      },
    ],
  },
  {
    path: `*`,
    element: <PageNotFound />,
  },
];
export { authRoutes, appRoutes };
