import { useState, useEffect } from "react";

const useBattery = (recording) => {
  const [batteryState, setBatteryState] = useState({
    charging: false,
    chargingTime: 0,
    dischargingTime: 0,
    level: 1,
    isSupported: false,
    fetched: false,
  });

  useEffect(() => {
    let battery;
    let isMounted = true;

    const updateBatteryInfo = () => {
      if (!isMounted || !battery) {
        return;
      }
      setBatteryState({
        charging: battery.charging,
        chargingTime: battery.chargingTime,
        dischargingTime: battery.dischargingTime,
        level: battery.level,
        isSupported: true,
        fetched: true,
      });
    };

    navigator.getBattery().then((bat) => {
      if (!isMounted || !recording) {
        return;
      }
      battery = bat;
      battery.addEventListener("chargingchange", updateBatteryInfo);
      battery.addEventListener("chargingtimechange", updateBatteryInfo);
      battery.addEventListener("dischargingtimechange", updateBatteryInfo);
      battery.addEventListener("levelchange", updateBatteryInfo);
      updateBatteryInfo();
    });

    return () => {
      isMounted = false;
      if (battery) {
        battery.removeEventListener("chargingchange", updateBatteryInfo);
        battery.removeEventListener("chargingtimechange", updateBatteryInfo);
        battery.removeEventListener("dischargingtimechange", updateBatteryInfo);
        battery.removeEventListener("levelchange", updateBatteryInfo);
      }
    };
  }, [recording]);

  return batteryState;
};

export default useBattery;
