import axios from "axios";
import ErrorInterceptor from "./interceptors/error.interceptor";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

ErrorInterceptor(http);

export default http;
