import React from "react";

const Recordings = () => {
  const render = () => {
    return <p>Recordings</p>;
  };
  return render();
};

export default Recordings;
