import React from "react";
import Accelerometer from "../../sensors/Accelerometer";
import Battery from "../../sensors/Battery";
import MicrophoneMeter from "../../sensors/MicrophoneMeter";
import { SensorType } from "../../../../helpers/constant";
import Geolocation from "../../sensors/GeoLocation";
import Camera from "../../sensors/Camera";

const FeatureList = (props) => {
  const renderFeatureList = () => {
    return (
      <>
        <Accelerometer />
        <Accelerometer type={SensorType.Gyroscope} />
        <Accelerometer type={SensorType.Magnetometer} />
        <Accelerometer type={SensorType.AmbientLightSensor} />
        <MicrophoneMeter />
        <Geolocation />
        <Battery />
        <Camera />
      </>
    );
  };

  const render = () => {
    return <>{renderFeatureList()}</>;
  };

  return render();
};

export default FeatureList;
