import React, { useEffect, useRef, useState } from "react";
import useSensor from "../../../hooks/useSensor";
import constant, {
  AccelerometerSensor,
  AmbientLightSensor,
  GyroscopeSensor,
  MagnetoMeterSensor,
  SensorType,
} from "../../../helpers/constant";
import { useSensorContext } from "../../../contexts/sensor.context";
import FeatureItem from "../../common/FeatureItem";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";

const Accelerometer = ({ type = SensorType.Accelerometer }) => {
  const getSensorStatus = () => {
    switch (type) {
      case SensorType.Gyroscope:
        return getStorageItem(GyroscopeSensor.id);
      case SensorType.AmbientLightSensor:
        return getStorageItem(AmbientLightSensor.id);
      case SensorType.Magnetometer:
        return getStorageItem(MagnetoMeterSensor.id);
      default:
        return getStorageItem(AccelerometerSensor.id);
    }
  };

  const getSensorItem = () => {
    switch (type) {
      case SensorType.Gyroscope:
        return GyroscopeSensor;
      case SensorType.AmbientLightSensor:
        return AmbientLightSensor;
      case SensorType.Magnetometer:
        return MagnetoMeterSensor;
      default:
        return AccelerometerSensor;
    }
  };

  const sensorType = useRef(type);
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(getSensorStatus()?.active);
  const { x, y, z, quaternion, error } = useSensor(
    sensorType.current,
    recording && active,
    {
      frequency:
        getSensorStatus()?.frequency / constant.defaultFrequency ||
        constant.defaultSensorFrequency,
    }
  );

  useEffect(() => {
    if (x != null && y != null && z != null) {
      const item = getSensorItem(type);
      sendSensorData(item.id, { x, y, z });
    }
  }, [x, y, z]);

  useEffect(() => {
    const item = getSensorItem();
    const data = getStorageItem(item.id);
    if (data && !data.frequency) {
      data.frequency = constant.defaultFrequency;
    }
    setStorageItem(item.id, { ...data, active });
  }, [active]);

  const render = () => {
    return (
      <>
        <FeatureItem
          item={getSensorItem()}
          active={active}
          setActive={() => setActive(!active)}
        />
      </>
    );
  };
  return render();
};

export default Accelerometer;
