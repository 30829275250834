import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Protected from "../components/common/Protected";
import { useAppContext } from "../contexts/app.context";
import CustomLoader from "../components/common/CustomLoader";
import BasePageContainer from "../components/common/BasePageContainer";

const DashboardPage = () => {
  const { isLoading, deviceId, setDeviceId } = useAppContext();

  useEffect(() => {
    if (!deviceId) {
      setDeviceId();
    }
  }, [deviceId]);

  const render = () => {
    if (isLoading) return <CustomLoader full />;
    return (
      <Protected>
        <BasePageContainer>
          <Outlet />
        </BasePageContainer>
      </Protected>
    );
  };
  return render();
};

export default DashboardPage;
