import React, { useRef } from "react";
import { useSensorContext } from "../../../contexts/sensor.context";
import FeatureList from "./feature-list/FeatureList";
import RecordButton from "../../common/RecordButton";
import { getStorageItem } from "../../../helpers/shared-info";
import constant from "../../../helpers/constant";
import { convertToArrayOfObjects } from "../../../helpers/helper";

const Features = () => {
  const schedule = useRef(getStorageItem(constant.STORAGE.sheduleConfig));
  const renderRecordButton = () => {
    let list = [];
    if (schedule.current) {
      list = convertToArrayOfObjects(schedule.current).map((i) => i.value);
    }
    return <RecordButton schedule={list} />;
  };

  const renderFeatures = () => {
    return (
      <section className="featuresec">
        <div className="d-flex flex-wrap">
          <FeatureList />
        </div>
      </section>
    );
  };

  const render = () => {
    return (
      <div>
        {renderFeatures()}
        {renderRecordButton()}
      </div>
    );
  };

  return render();
};

export default Features;
