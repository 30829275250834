// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  userInfo: null,
  authError: null,
  tenentInfo: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ACTION.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
        authError: null,
      };

    case ACTION.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        authError: action.payload,
      };

    case ACTION.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
      };
    default:
      return state;
  }
};

const useAuthReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useAuthReducer;
