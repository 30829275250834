import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSensorContext } from "../../contexts/sensor.context";
import { getStorageItem } from "../../helpers/shared-info";
import constant from "../../helpers/constant";
import { convertToArrayOfObjects, getDayName } from "../../helpers/helper";

const RecordButton = ({ schedule }) => {
  const { recording, updateRecording } = useSensorContext();
  const startRef = useRef();
  const stopRef = useRef();

  const getCurrentTime = () => {
    const now = new Date();
    return now.getHours() * constant.oneMinute + now.getMinutes(); // Convert current time to minutes since midnight
  };
  const getMinutes = (time) => {
    const [hour, minutes] = time.split(":").map(Number);
    return hour * constant.oneMinute + minutes;
  };

  const calculateTimeout = (targetTime) => {
    const now = getCurrentTime();
    const diff = targetTime - now;
    return diff > 0 ? diff * constant.oneMinute * constant.oneSecond : 0; // Return milliseconds until target time
  };

  const setNextSchedule = () => {
    const now = getCurrentTime();
    const upcomingSchedule = schedule.find(({ enabled, start }) => {
      const startTime = getMinutes(start);
      return enabled && startTime >= now;
    });

    if (upcomingSchedule && upcomingSchedule) {
      const startTimeout = calculateTimeout(getMinutes(upcomingSchedule.start));
      const stopTimeout = calculateTimeout(getMinutes(upcomingSchedule.end));
      createTimeout(startTimeout, stopTimeout);
    }
  };

  const createTimeout = (startTimeout, stopTimeout) => {
    console.log(startTimeout, stopTimeout);
    startRef.current = setTimeout(() => {
      console.log("start");
      updateRecording(true);
      stopRef.current = setTimeout(() => {
        console.log("end");
        updateRecording(false);
        setNextSchedule();
      }, stopTimeout);
    }, startTimeout);
  };

  useEffect(() => {
    if (schedule.length) {
      setNextSchedule();
    }
    return () => {
      clearTimeout(startRef.current);
      clearTimeout(stopRef.current);
    }; // Clean up on unmount
  }, []);

  useEffect(() => {
    let lock;

    const requestWakeLock = async () => {
      try {
        lock = await navigator.wakeLock.request("screen");
        lock.addEventListener("release", () => {
          console.log("Wake Lock released");
        });
        console.log("Wake Lock is active");
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };
    if (recording) {
      requestWakeLock();
    }

    // Clean up on component unmount
    return () => {
      if (lock) {
        lock.release();
      }
    };
  }, [recording]);

  const render = () => {
    return (
      <div className="recorsec p-3">
        <Link
          id="button-background"
          onClick={() => updateRecording(!recording)}
        >
          <span className="slide-text">
            {recording ? "" : "Start Recording"}
          </span>
          <div id="slider" className={`${recording ? "unlocked" : ""}`}>
            <i id="locker" className="material-icons locktext">
              <span>{recording ? "Stop Recording" : ""}</span>
              {recording ? (
                <svg
                  className="pausebtn"
                  width="28"
                  height="28"
                  viewBox="0 0 43 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5 0.9375C17.4331 0.9375 13.4576 2.14347 10.0761 4.40291C6.69461 6.66234 4.05906 9.87377 2.50274 13.6311C0.946409 17.3884 0.539203 21.5228 1.33261 25.5115C2.12602 29.5003 4.08441 33.1642 6.96013 36.0399C9.83584 38.9156 13.4997 40.874 17.4885 41.6674C21.4772 42.4608 25.6116 42.0536 29.3689 40.4973C33.1262 38.9409 36.3377 36.3054 38.5971 32.9239C40.8565 29.5424 42.0625 25.5669 42.0625 21.5C42.0625 16.0465 39.8961 10.8163 36.0399 6.96012C32.1837 3.1039 26.9535 0.9375 21.5 0.9375ZM30.3125 27.375C30.3125 28.1541 30.003 28.9012 29.4521 29.4521C28.9012 30.003 28.1541 30.3125 27.375 30.3125H15.625C14.8459 30.3125 14.0988 30.003 13.5479 29.4521C12.997 28.9012 12.6875 28.1541 12.6875 27.375V15.625C12.6875 14.8459 12.997 14.0988 13.5479 13.5479C14.0988 12.997 14.8459 12.6875 15.625 12.6875H27.375C28.1541 12.6875 28.9012 12.997 29.4521 13.5479C30.003 14.0988 30.3125 14.8459 30.3125 15.625V27.375Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  className="cursorgrab"
                  width="24"
                  height="24"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M14 1.75C7.2625 1.75 1.75 7.2625 1.75 14C1.75 20.7375 7.2625 26.25 14 26.25C20.7375 26.25 26.25 20.7375 26.25 14C26.25 7.2625 20.7375 1.75 14 1.75ZM14 19.25C11.1125 19.25 8.75 16.8875 8.75 14C8.75 11.1125 11.1125 8.75 14 8.75C16.8875 8.75 19.25 11.1125 19.25 14C19.25 16.8875 16.8875 19.25 14 19.25Z"
                    fill="#149557"
                  />
                </svg>
              )}
            </i>
          </div>
        </Link>
      </div>
    );
  };

  return render();
};

export default RecordButton;
