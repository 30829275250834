let sharedInfo = {};

export const setSharedInfo = (key, data) => {
  if (key) {
    sharedInfo[key] = data;
  }
};

export const getSharedInfo = (key) => sharedInfo[key];

export const clearSharedInfo = (key) => (sharedInfo[key] = null);

export const setStorageItem = (key, data) => {
  if (key) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getStorageItem = (key) => {
  const data = window.localStorage.getItem(key);
  try {
    if (data) return JSON.parse(data);
  } catch (ex) {}
  return data;
};
