import React from "react";
import { Link } from "react-router-dom";
import { useSensorContext } from "../../contexts/sensor.context";

const FeatureItem = (props) => {
  const { item, active, setActive } = props;
  const { recording } = useSensorContext();

  const renderItem = () => {
    return (
      <Link
        className={`featurebox w-50 text-center px-2 py-4 ${
          active ? "featurebox-selected" : ""
        } ${recording ? "featurebox-disabled" : ""}`}
        onClick={() => setActive(!active)}
      >
        <div className="featureicon mx-auto d-flex align-items-center justify-content-center rounded-pill mb-3">
          {item.icon}
        </div>
        <h2>{item.value}</h2>
      </Link>
    );
  };

  const render = () => {
    return <>{renderItem()}</>;
  };
  return render();
};

export default FeatureItem;
