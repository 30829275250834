import React, { useEffect, useState } from "react";
import useMicrophoneStrength from "../../../hooks/useMicrophoneStrength";
import constant, { MicrophoneSensor } from "../../../helpers/constant";
import FeatureItem from "../../common/FeatureItem";
import { useSensorContext } from "../../../contexts/sensor.context";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";

const MicrophoneMeter = () => {
  const existingData = getStorageItem(MicrophoneSensor.id);
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(existingData?.active);
  const dB = useMicrophoneStrength(recording && active, {
    frequency: existingData?.frequency,
  });

  useEffect(() => {
    if (dB) {
      sendSensorData(MicrophoneSensor.id, { dB });
    }
  }, [dB]);

  useEffect(() => {
    const data = getStorageItem(MicrophoneSensor.id);
    if (data && !data.frequency) {
      data.frequency = constant.defaultFrequency;
    }
    setStorageItem(MicrophoneSensor.id, { ...data, active });
  }, [active]);

  const render = () => {
    return (
      <>
        <FeatureItem
          item={MicrophoneSensor}
          active={active}
          setActive={() => setActive(!active)}
        />
      </>
    );
  };
  return render();
};

export default MicrophoneMeter;
