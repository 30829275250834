import { v4 as uuidv4 } from "uuid";
import constant, { WeekDays } from "./constant";
import Moment from "moment";

export const stringToHslColor = (str, s, l) => {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
export const getUUID = () => uuidv4();

export const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0]?.toUpperCase())
    .join("");
};
export const formatNumberInMillions = (value) => {
  const formater = Intl.NumberFormat("en", {
    notation: "standard",
    maximumFractionDigits: 0,
  });
  return formater.format(value);
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const hasNoEmptyObjects = (array) => {
  return array.every((item) => !isEmptyObject(item));
};

export const formatDate = (date, format = constant.dateFormat) => {
  return Moment(date || new Date()).format(format);
};

export const formatDateInDays = (date) => {
  const targetDate = Moment(date);
  return targetDate.fromNow();
};
export const formatToCurrency = (v, currency = constant.defLocale.currency) => {
  // return Number(v).toLocaleString(constant.defLocale.locale, { style: "currency", currency: currency, currencyDisplay: "symbol" });
  return `$ ${formatNumberInMillions(v)}`;
};
export const currencyDifference = (af, be) => {
  return formatToCurrency(Number(af) - Number(be));
};

export const sumOfObject = (data) => {
  return Object.values(data).reduce((acc, curr) => acc + curr, 0);
};

export const getUniqueModelIds = (list) => {
  const tasks = [];
  list.map((item) => {
    return tasks.push(...item.tasks);
  });
  return Array.from(
    tasks.reduce((set, obj) => {
      set.add(obj.modelId);
      return set;
    }, new Set())
  );
};

export const validateEmail = (email) => {
  // Regular expression for email validation
  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) || phoneRegex.test(email);
};

export const calculatePercentage = (value, base) => {
  return (Number(value) / Number(base)) * 100;
};

export const getTotalOfProperty = (list, key) =>
  list.reduce((acc, current) => acc + parseInt(current[key] || 0, 10), 0);

export const roundOff = (value) => Math.round(value);

export const openWindow = (url, options = {}) => {
  const { target, width = 800, height = 600 } = options;
  return window.open(
    url,
    target || constant.AppTitle,
    `toolbar=0,status=0,width=${width},height=${height}`
  );
};

export const getDynamicColor = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

  r = color >> 16;
  g = (color >> 8) & 255;
  b = color & 255;

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) return "#333";

  return "#EEE";
};

export const convertToArrayOfObjects = (data) => {
  return Object.entries(data).map(([key, value]) => ({ key, value }));
};

export const getHighestByParameter = (array, parameter) => {
  return array.reduce((prev, current) =>
    prev[parameter] > current[parameter] ? prev : current
  );
};

export const convertArrayToObjects = (arr) => {
  let obj = {};
  arr.forEach((item) => {
    obj[item.label] = item.value;
  });
  return obj;
};

export const getTimeStamp = () => Date.now();

export const convertToNumber = (num, decimal) =>
  Number(Number(num).toFixed(decimal || 1));

export const getFrequencyForSample = (frequency) => {
  return (
    Math.floor(constant.oneMinute / parseInt(frequency)) * constant.oneSecond
  );
};

export const getDayName = (date = new Date()) => {
  const daysOfWeek = [
    WeekDays.Sunday,
    WeekDays.Monday,
    WeekDays.Tuesday,
    WeekDays.Wednesday,
    WeekDays.Thursday,
    WeekDays.Friday,
    WeekDays.Saturday,
  ];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};