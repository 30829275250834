import React from "react";
import AppHeader from "./Header";
import Footer from "./Footer";
import { useAppContext } from "../../contexts/app.context";

const BasePageContainer = (props) => {
  const { deviceId } = useAppContext();
  const renderDeviceId = () => {
    return (
      <h6 className="d-flex justify-content-center py-1 align-items-center nvh-gap-1">
        <p className="p-0">DeviceId: {deviceId}</p>
      </h6>
    );
  };
  const render = () => {
    return (
      <div>
        <AppHeader />
        {renderDeviceId()}
        {props.children}
        <Footer />
      </div>
    );
  };
  return render();
};

export default BasePageContainer;
