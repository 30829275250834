// useGeolocation.js
import { useState, useEffect } from "react";
import { getFrequencyForSample } from "../helpers/helper";

const useGeolocation = (recording, { frequency }) => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
    error: null,
  });

  useEffect(() => {
    if (!recording) {
      return;
    }
    if (!navigator.geolocation) {
      setLocation((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by your browser",
      }));
      return;
    }

    const success = (position) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        speed: position.coords.speed,
        error: null,
      });
    };

    const error = (err) => {
      setLocation((prevState) => ({
        ...prevState,
        error: err.message,
      }));
    };

    const updatePosition = () =>
      navigator.geolocation.getCurrentPosition(success, error);
    const intervalId = setInterval(
      updatePosition,
      getFrequencyForSample(frequency)
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [recording, frequency]);

  return location;
};

export default useGeolocation;
