import React, { useEffect, useState } from "react";
import { useSensorContext } from "../../../contexts/sensor.context";
import { CameraSensor } from "../../../helpers/constant";
import FeatureItem from "../../common/FeatureItem";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";
import useCamera from "../../../hooks/useCamera";

const Camera = () => {
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(getStorageItem(CameraSensor.id)?.active);
  const { videoRef } = useCamera(recording);

  useEffect(() => {
    setStorageItem(CameraSensor.id, { active });
  }, [active]);

  const renderCameraView = () => {
    return (
      <div className={`w-50 text-center`}>
        <video
          ref={videoRef}
          autoPlay
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    );
  };

  const render = () => {
    if (recording && active) {
      return renderCameraView();
    }
    return (
      <>
        <FeatureItem
          item={CameraSensor}
          active={active}
          setActive={() => setActive(!active)}
        />
      </>
    );
  };
  return render();
};

export default Camera;
